import "@fontsource/cormorant-garamond";

import { useEffect, useRef, useState } from "react";
import "./App.css";
import Lottie from "lottie-react";

// messages

import { loveMessages } from "./data/loveMessages";

// flowers
import flower_1 from "./assets/animations/flowers/flower_1.json";
import flower_2 from "./assets/animations/flowers/flower_2.json";
import flower_3 from "./assets/animations/flowers/flower_3.json";
import flower_4 from "./assets/animations/flowers/flower_4.json";
import flower_5 from "./assets/animations/flowers/flower_5.json";
import flower_6 from "./assets/animations/flowers/flower_6.json";
import flower_7 from "./assets/animations/flowers/flower_7.json";
import flower_8 from "./assets/animations/flowers/flower_8.json";
import flower_9 from "./assets/animations/flowers/flower_9.json";
import flower_10 from "./assets/animations/flowers/flower_10.json";
import flower_11 from "./assets/animations/flowers/flower_11.json";
import flower_12 from "./assets/animations/flowers/flower_12.json";
import flower_13 from "./assets/animations/flowers/flower_13.json";
import flower_14 from "./assets/animations/flowers/flower_14.json";

import yellow_flower_1 from "./assets/animations/flowers/flower_yellow_1.json";
import yellow_flower_2 from "./assets/animations/flowers/flower_yellow_2.json";
import yellow_flower_3 from "./assets/animations/flowers/flower_yellow_3.json";

function App() {
  const lottieRef = useRef();

  const [flower, setFlower] = useState<any>(null);
  const [message, setMessage] = useState<any>();

  const flowers = [
    flower_1,
    flower_2,
    flower_3,
    flower_4,
    flower_5,
    flower_6,
    flower_7,
    flower_8,
    flower_9,
    flower_10,
    flower_11,
    flower_12,
    flower_13,
    flower_14,
    yellow_flower_1,
    yellow_flower_2,
    yellow_flower_3,
  ];

  const yellow_flowers = [yellow_flower_1, yellow_flower_2, yellow_flower_3];

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * flowers.length);
    setFlower(flowers[randomIndex]);
  }, []);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * loveMessages.length);
    setMessage(loveMessages[randomIndex]);
  }, []);

  useEffect(() => {
    if (lottieRef && lottieRef.current)
      //@ts-ignore
      lottieRef.current.setSpeed(0.4);
  }, []);
  return (
    <div className='w-[100vw] h-[100vh] flex justify-center'>
      <div className='flex justify-center w-full h-full max-w-[600px]'>
        <div className='w-[85%] mt-10 mb-10'>
          {/*@ts-ignore */}
          <Lottie animationData={flower} lottieRef={lottieRef} />
          <div className='w-full mt-10'>{message}</div>
        </div>
      </div>
    </div>
  );
}

export default App;
